/**
 * Global Styles - App
 *
 * Any global styles overrides or additional styles can be included in this file
 */
import React from 'react';
import { createGlobalStyle, Normalize, thd } from '@smooth-ui/core-sc';
import loriGlobalStyles from '_platform/src/theme/globalStyles';

export const GlobalStylesApp = createGlobalStyle`
  ${loriGlobalStyles}

  @import url("https://use.typekit.net/gmd2ijq.css");

  html {
    font-size: 18px;
  }

  body {
    font-family: forma-djr-micro, sans-serif;
    font-size: 16px;
    line-height: 1.6;
  }

  h1 {
    font-family: forma-djr-display, sans-serif;
    font-weight: normal;
    font-size: 40px;
    margin-bottom: 2rem;
  }
  h1.title {
    font-family: forma-djr-display, sans-serif;
    font-weight: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 1;
  }
  h2 {
    font-family: forma-djr-display, sans-serif;
    font-weight: normal;
    font-weight: 400;
    font-size: 36px;
    margin-bottom: 2rem;
  }
  h3 {
    font-family: forma-djr-display, sans-serif;
    font-weight: normal;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 2rem;
  }

  p {
    color: ${thd('text', '#32343B')};
    padding-bottom: 1.1rem;
  }

  a {
    color: #000;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  button.sui-button,
  .cart-cta a {
    background-color: ${thd('primary', '#D19C45')};
    color: #000;
    font-size: 16px;
    font-family: forma-djr-display, sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    &:hover {
      color: #000;
    }
  }

  /* Don't wrap emails, phones or other non-wrapping strings */
  .nowrap,
  .date,
  .email,
  .phone {
    white-space: nowrap;
  }

  .text--black {
    color: #000;
  }

  /* Color text */
  .text--red {
    color: ${thd('danger', '#dc3545')};
  }

  .text--white {
    color: #fff;
  }

  .text--gold {
    color: ${thd('primary', '#867455')};
  }

  /* Align text */
  .text-center,
  .text-centre,
  .text--center,
  .text--centre {
    text-align: center;
  }
  .text-right,
  .text--right {
    text-align: right;
  }
  .text-left,
  .text--left {
    text-align: left;
  }

  .text-small,
  .text--small {
    font-size: 80%;
  }

  .text-large,
  .text--large {
    font-size: 120%;
  }

  .text-larger,
  .text--larger {
    font-size: 200%;
  }

  .text-normal,
  .text--normal {
    font-weight: normal;
  }

  .text-bold,
  .text--bold {
    font-weight: bold;
  }

  .text-uppercase,
  .text--uppercase {
    text-transform: uppercase;
  }

  .text--tight {
    padding-bottom: 0;
    padding-top: 0;
  }

  /* Handy spacing classes */
  :root {
    --spacer: 2rem;
  }

  .spacer {
    padding-bottom: 40px;
    padding-top: 40px;
    padding-bottom: var(--spacer);
    padding-top: var(--spacer);
  }
  .spacer--small {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-bottom: calc(var(--spacer) / 2);
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--large {
    padding-bottom: 80px;
    padding-top: 80px;
    padding-bottom: calc(var(--spacer) * 2);
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--around {
    padding: 40px;
    padding: var(--spacer);
  }
  .spacer--around--small {
    padding: 20px;
    padding: calc(var(--spacer) / 2);
  }
  .spacer--around--large {
    padding: 80px;
    padding: calc(var(--spacer) * 2);
  }
  .spacer--top {
    padding-top: 40px;
    padding-top: var(--spacer);
  }
  .spacer--bottom {
    padding-bottom: 40px;
    padding-bottom: var(--spacer);
  }
  .spacer--top--small,
  .spacer--small--top {
    padding-top: 20px;
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--bottom--small,
  .spacer--small--bottom {
    padding-bottom: 20px;
    padding-bottom: calc(var(--spacer) / 2);
  }
  .spacer--top--large,
  .spacer--large--top {
    padding-top: 80px;
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--bottom--large,
  .spacer--large--bottom {
    padding-bottom: 80px;
    padding-bottom: calc(var(--spacer) * 2);
  }

  .Toastify .Toastify__toast--success {
    background: ${thd('primary', '#07bc0c')};
  }

  .status--Gold {
    background-color: #d09b42;
  }

  .status--Platinum {
    background-color: #7b7b7b;
  }

  .status--Silver {
    background-color: #b7b7b7;
  }

  .tier-- {
    padding-top: 10px;
    color: #fff;

    p {
      color: #fff;
    }
  }

  .tier--Gold {
    color: #fff;
    padding-top: 10px;
  }

  .tier--Platinum {
    color: #fff;
    padding-top: 10px;
  }

  .tier--Silver {
    color: #fff;
    padding-top: 10px;
  }

  hr {
    border: 0;
    border-top: 1px solid #fff;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.black {
    border: 0;
    border-top: 1px solid #000;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.orange {
    border: 0;
    border-top: 1px solid #ff8900;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.purple {
    border: 0;
    border-top: 1px solid #b655f5;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.pink {
    border: 0;
    border-top: 1px solid #ff84ff;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.yellow {
    border: 0;
    border-top: 1px solid #ffe137;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  hr.lime {
    border: 0;
    border-top: 1px solid #d3fb66;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
`;

const StylesApp = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStylesApp />
  </React.Fragment>
);

export default StylesApp;
