import React from 'react';
import PropTypes from 'prop-types';

import svgSprites from './svg-sprites.svg';

const SvgIcon = ({
  icon,
  scaleWidth,
  scaleHeight,
  width,
  height,
  fill,
  color,
  title,
}) => {
  const svgWidth = width || 'default';
  const svgHeight = height || 'default';
  const svgFill = fill || 'default';
  const svgColor = color || '#fff';
  const svgTitle = title || icon || 'Icon';

  return (
    <svg
      viewBox={`0 0 ${scaleWidth} ${scaleHeight}`}
      className={`icon icon--${icon}`}
      style={{
        color: `${svgColor}`,
        fill: `${svgFill}`,
        height: `${svgHeight}`,
        width: `${svgWidth}`,
      }}
    >
      <title>{svgTitle}</title>
      <use xlinkHref={`${svgSprites}#${icon}`} />
    </svg>
  );
};

SvgIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  scaleHeight: PropTypes.string,
  scaleWidth: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

SvgIcon.defaultProps = {
  fill: undefined,
  height: undefined,
  scaleHeight: undefined,
  scaleWidth: undefined,
  title: undefined,
  width: undefined,
};

export default SvgIcon;
