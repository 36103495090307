import React from 'react';

// Style and SEO
import { css, up, styled } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';
import SvgIcon from '../../CustomComponents/SvgIcon/SvgIcon';

const FooterContainer = styled.footer`
  background-color: #000;
  border-top: 16px solid #fff;
  padding: 2rem 1rem 1rem 1rem;
  p {
    color: #fff;
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterLogoWrapper = styled.div`
  display: block;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  height: 75px;
  width: 195px;
`;

const RegisterInterestFooter = () => {
  return (
    <FooterContainer>
      <FooterLogoWrapper>
        <SvgIcon
          icon="footer-logo"
          scaleWidth="64"
          scaleHeight="24"
          width="192px"
          height="72px"
          fill="#fff"
          title="Hewlett Packard Platinum Club"
        />
      </FooterLogoWrapper>
      <ul>
        <li>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
      </ul>
      <p className="text--center">&copy; HP Platinum Club 2023</p>
    </FooterContainer>
  );
};

export default RegisterInterestFooter;
